import React from 'react'
import Layout from '../components/layout/'
import PageWrapper from '../components/pageWrapper/'
import styles from './styles/404.module.css'

const NotFoundPage = () => (
  <Layout>
    <PageWrapper>
      <div className={styles.contentBlock}>
        <h1>404 PAGE NOT FOUND</h1>
      </div>
    </PageWrapper>
  </Layout>
)

export default NotFoundPage
